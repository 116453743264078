
import React from "react"

import { LayoutWrapper,  MainWrapper } from './styled'

const Layout = ({ children }) => {

  return (

    <LayoutWrapper>
    <MainWrapper>{children}</MainWrapper>
    </LayoutWrapper>

  )
}


export default Layout
