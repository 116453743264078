import styled from 'styled-components'
import media from 'styled-media-query'

export const LayoutWrapper = styled.div`
  width: 100%;
  height: 100vh;
`

export const MainWrapper = styled.main`
  display: grid;
  grid-template-columns: 70% 30%;
  grid-template-rows: auto auto;
  grid-template-areas: 
    'L R'
  ;

  ${media.lessThan("medium")`
    display: flex;
    flex-direction: column;
  `}

  background: linear-gradient(329.54deg, #FDD579 0%, #FFFACA 100%);
`

export const LeftWrapper = styled.div`
  grid-area: L;

  width: 100%;
`

export const RightWrapper = styled.div`
  grid-area: R;

  width: 100%;
  height: 100vh;

  position: sticky;
  top:0;

  ${media.lessThan("medium")`
    display: none;
  `}
`